:root {
    --psuedo-bg-color: #01AFDF;
    --psuedo-bg-color-dark: #0287ac;
    --psuedo-hover-transform: scaleX(0);
    --psuedo-font-color: snow;
}


.button {
    all: unset;
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .25em .75em;
    border-radius: .25em;
    font-family: 'Secular One', sans-serif;    
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 1px;
    cursor: pointer;
    overflow: hidden;
    background-color: var(-psuedo-bg-color);
    color: var(--psuedo-font-color);
    transform: scale(1);
    transition: transform 0.08s ease-in-out;
}
.button:active {
    transform: scale(.99);
    box-shadow: 2px 2px 6px inset black;
}
.button:active:enabled {
    transform: scale(.99);
    box-shadow: 2px 2px 6px inset black;
}
.button:not(:enabled) {
    transform: scale(1);
    box-shadow: 0;
    pointer-events: none;
}
.button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25%;
    background-color: var(--psuedo-bg-color);
    opacity: .2;    
    z-index: -1;
    transform:  scaleX(0);
    transform-origin: center;
    transition: transform .2s ease-in-out, opacity 0.2s ease-in-out;
}
.button.hover::after { 
    transform: scaleX(1.5);
}
.button:active::after { 
    background-color: var(--psuedo-bg-color);
    box-shadow: 1px 1px 4px inset black;
}


.button.outline {
    background-color: transparent;
    border: 2px solid var(--psuedo-bg-color);
}