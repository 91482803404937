.signup_form {
    position: relative;
    width: 100%;
    /* height: 100%; */
    font-family: 'Secular One', sans-serif;
}

.logo_row {
    position: relative;
    padding: 1em;
    text-align: center;
}

.form_row {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.form_row form {
    position: relative;
    width: 100%;
    padding: 0 2em;
}

.checkbox_row {
    position: relative;
    display: flex;
    justify-content: center;
    gap: .75em;
    flex-wrap: nowrap;
    margin-bottom: 1em;
}
.checkbox_row span {
    position: relative;
    display: flex;
    align-items: center;
    color: snow;
}

.form_modal {
    position: absolute;
    width: 100vw;
    height: 100vh;   
    /* opacity: 0; */
    background-color: rgba(55, 55, 55, 0.98);
    transition: opacity .4s ease-in-out;
}
.form_modal.showing {
    /* opacity: 1; */
}
.modal_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 5em;
}
.confirm_modal {
    position: relative;
    padding: 2.5em 2.5em;
    text-align: center;
    border-radius: 10px;
    background-color: #2b3990;
    font-family: 'Secular One', sans-serif;
    color: rgba(255, 250, 250, 0.76);    
    font-size: 1.25rem;       
}
.confirm_modal h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: .5em;
    color: snow;
}

.cmp_logo {
    margin-bottom: 1em;
}


@media screen and (min-width: 600px) {
    .form_row form {
        width: 50%;
    }
}
