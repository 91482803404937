.container {
    position: relative;
    display: flex;    
    justify-content: center;
    /* align-items: center; */
    /* padding: .75em; */
}
.container .is-hidden {
    width: 0;
    padding: 0;
}
.container .is-hidden input {
    opacity: 0;
}
.input_wrapper {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.input_wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    width: 30px;
    background-color: #eee;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;  
}
.input_wrapper:hover input ~ .checkmark {
    background-color: #fff;
}
.input_wrapper input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.input_wrapper input:checked ~ .checkmark {
    background-color: #2196F3;
    /* background-color: var(--glds-green); */
    outline: 0;
}
.input_wrapper input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;
}
.input_wrapper input:focus ~ .checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.input_wrapper input:checked ~ .checkmark:after {
    display: block;
}
.input_wrapper .checkmark:after {
    left: 50%;
    top: 45%;
    width: 8px;
    height: 17px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translate(-50%,-50%) rotate(45deg);
}
.input_wrapper .checkmark.changed:after {
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}