.container {
    --background: #102A43;
    --border-clr: #486581;
    --text-clr: #E6EBE0;
    --load-svg-clr: #FFCF56;
    --subtext-clr: rgba(230, 235, 224, 0.5);
    --error-clr: #ED6A5A;
    --success-clr: #0CCE6B;     

    position: relative;
    margin-bottom: 1em;
    width: 100%;
}

.container.theme_lite {
    --background: #486581;
    --border-clr: #E6EBE0;
    --text-clr: #102A43;
    --load-svg-clr: #FFCF56;
    --subtext-clr: rgba(230, 235, 224, 0.5);
    --error-clr: #ED6A5A;
    --success-clr: #0CCE6B;
}


.close_btn {
    position: absolute;
    top: 50%;
    right: 0;
    padding: .5em;
    border: 0;
    outline: 0;
    background-color: transparent;
    box-shadow: 0;
    color: snow;
    transform: translateY(-50%);
}


/***** STATUS COMPONENT STYLE START *****/
.status {
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    z-index: 1;
}
/***** STATUS COMPONENT STYLE END *****/


/***** INPUT COMPONENT STYLE START *****/
.text_field {
    position: relative;
    display: block;  
    width: 100%;
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.863);
    background-clip: padding-box;
    border: 1px solid var(--background);
    appearance: none;
    border-radius: .25em;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Secular One', sans-serif;
    color: black;
}
.text_field::placeholder {
    color: transparent;
}
.text_field:focus {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    outline: 0;
    border: 2px solid #102A43;
    background-color: rgba(255, 255, 255, 0.863);
    color: black;
    box-shadow: 2px 2px 8px inset rgb(0 0 0 / 50%), -2px -2px 8px inset rgb(189 189 189 / 50%)
}
.text_field:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.container.is_error .text_field {
    border: 3px solid var(--error-clr);
    animation: shake .1s linear;
    animation-iteration-count: 3;
}
.container.is_error .text_field {
    animation: shake .1s linear;
    animation-iteration-count: 3;
    transition: all .2s ease-in-out;
}
.container.is_error .text_field:focus {
    border: 2px solid var(--error-clr);
    animation: shake .1s linear;
    animation-iteration-count: 3;
}




.text_field:focus ~ label {
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}
.text_field:not(:placeholder-shown) ~ label {
    opacity: .65;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}
.container label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    font-family: futura;
    color: black;
    text-transform: capitalize;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}
.container.is_error > label {
    color: var(--error-clr);
}


/***** Theme styles *****/
[class*="theme-"] {
    background: var(--border-clr);
    border: 1px solid var(--background);
    color: var(--text-clr);
}


.theme-bright {
    --border-clr: #00B2CA;
    --background: #1D4E89;
    --text-clr: #FBD1A2;
}




@keyframes shake {
    0% {         
        left: -5px;
    }
    100% {
        right: -5px;
    }
}
