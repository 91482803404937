.App {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    background-color: #2b3990;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
